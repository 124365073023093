.loginMainWrap {
  background-color: #202027;
  box-shadow: 0px 2px 4px 0px #ffffff05 inset;
  padding: 16px;
  position: relative;
  min-width: 440px;
  height: 100vh;
}
.loginMainWrap::before {
  background-image: url(../../../../public/images/loginTopShadow.png);
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  /* left: 46px; */
  background-position-x: left;
}
.loginHeadeWrap .closeIcon {
  background: var(--Black-500, #2b2b38);
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  z-index: 9999;
  cursor: pointer;
}
.loginMainWrap .loginHeadeWrap {
  display: flex;
  justify-content: end;
}
.loginMainWrap .loginLogoWrap {
  /* margin-top: -20px; */
  text-align: center;
}
.loginMainWrap .loginLogoWrap h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}
.loginLogoWrap img {
  height: 70px;
  width: 70px;
  margin-bottom: 5px;
}
.tabs-wrapperLogin {
  position: relative;
  z-index: 9999;
  margin-top: 32px;
}

.tabs-wrapperLogin .tabs-container {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  outline: 1px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 8px 0px #0000001f inset;
}

.tabs-wrapperLogin .tab-button {
  padding: 11px 20px;
  border: none;
  background-color: #1d1d23;
  font-family: Jost !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1;
  text-align: center;
  color: #757786;
}

.tabs-wrapperLogin .tab-button.active {
  background: var(--Black-500, #2b2b38);
  color: #fff;
  border: 1.4px solid #ffffff0a;
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  border-radius: 8px;
  font-family: Jost !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

/* .tabs-wrapperLogin .tab-button:hover {
  background-color: #2a2a2f;
} */

.tabs-wrapperLogin .tab-button:focus {
  outline: none;
}

.tab-content h2 {
  margin-bottom: 10px;
}

/* input login css start */

.inputWrap .input-container-login {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputWrap .input-label {
  font-family: Jost;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
  color: #757786;
}

.inputWrap .custom-input {
  padding: 7.2px 40px 6px 12px;
  width: 100%;
  border: none;
  border-radius: 8px !important;
  background-color: var(--Black-800, #1d1d23);
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  color: #fff;
  font-size: 16px;
  outline: none;
  transition: border 0.3s ease;
  position: relative;
}
.form-error-message-submit {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ea565b !important;
  margin: 4px 0 0 0;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}

.inputWrap .custom-input::placeholder {
  font-family: Jost;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #4d4d5b;
}
.inputWrap .password-input::placeholder {
  color: #ffffff !important;
  line-height: 20px;
  margin-top: 5px !important;
  display: block;
}

.inputWrap .custom-input:focus {
  border: none;
}
.loginFormMainWrap .inputWrap .input-login-form-wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.loginFormMainWrap .input-login-form-wrap .showPassword {
  position: absolute;
  top: 28px;
  right: 12px;
  height: 16px;
  width: 16px;
}
.position-relative {
  position: relative;
}
.loginFormMainWrap .forgotPasswordTxtWrap {
  margin-top: 4px;
  text-align: end;
}
.loginFormMainWrap .forgotPasswordTxtWrap a {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #757786;
  text-decoration: none;
}
.loginFormMainWrap .incorrectPassword {
  margin-top: 4px !important;
}
.loginFormMainWrap .incorrectPassword a {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #ea565b;
  text-decoration: none;
  display: none;
}
.enterMailMainWrap .input-container-login img {
  position: absolute;
  top: 27px;
  right: 12px;
}
.loginFormMainWrap .logInBtnWrap {
  margin-top: 16px;
}
.loginFormMainWrap .logInBtnWrap button {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  border: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  padding: 10.1px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  text-transform: initial;
}
.divider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.register-divider {
  margin-top: 16px;
}
.divider-text {
  color: #757786;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  position: relative;
  margin: 0 6px;
  font-family: Proxima Nova !important;
}

.divider-text::before,
.divider-text::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20%;
  height: 1px;
  background-color: #34343f;
}

.grandRegisterMainWrap .divider-text::before,
.grandRegisterMainWrap .divider-text::after {
  width: 37%;
}

.divider-text::before {
  left: -44px;
}

.divider-text::after {
  right: -44px;
}

.loginSocialBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: fixed;
  min-width: 408px;
  bottom: 50px;
}
.loginSocialBtnWrap button {
  background: var(--Black-500, #2b2b38);
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  width: 100%;
  text-transform: initial;
  padding: 9.2px;
  border-radius: 8px;
}
.loginFormMainWrap .loginSocialBtnWrap button img {
  margin-right: 8px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #1d1d23 inset !important;
  -webkit-text-fill-color: #fff !important;
}

/* Firefox */
input:-moz-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px #1d1d23 inset !important;
  color: #fff !important;
}

/* Internet Explorer */
input:-ms-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px #1d1d23 inset !important;
  color: #fff !important;
}

/* agree data css start */

.agreeDataMainWrap {
  background-color: #202027;
  box-shadow: 0px 2px 4px 0px #ffffff05 inset;
  padding: 16px;
  position: relative;
  height: 100vh;
  overflow: auto;
}
.agreeDataMainWrap .agreeHeader {
  display: flex;
  justify-content: space-between;
}
.agreeDataMainWrap .agreeHeader h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  max-width: 227px;
  margin: 0px;
}
.agreeDataMainWrap .agreeContentWrap {
  margin-top: 16px;
  touch-action: none;
  height: 80%;
}
.agreeDataMainWrap .agreeContentWrap p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #757786;
}
.agreeDataMainWrap .agreeContentWrap .agreeContentScrollWrap {
  /* max-height: calc(100vh - 180px); */
  background: var(--Black-800, #1d1d23);
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  overflow: auto;
  margin-top: 4px;
  border-radius: 8px;
  padding: 8px 12px;
  scrollbar-width: thin;
  height: 95%;
  overflow-anchor: none;
  overscroll-behavior: contain;
  scrollbar-color: #2b2b38 #2b2b38;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scroll: touch;
}
.agreeDataMainWrap .agreeContentWrap .agreeContentScrollWrap::after {
  content: "";
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  height: 25px;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(29, 29, 35, 0) -50%, #1d1d23 170%);
  width: 90%;
  margin: 0 auto;
}
.agreeDataMainWrap .loginSocialBtnWrap button {
  padding: 10.2px;
}
.agreeDataMainWrap .loginSocialBtnWrap .agreeBtn {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  border: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
}
.agreeContentScrollWrap span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #b0b2c2;
}
.marginTop15 {
  margin-top: 15px;
  display: block;
}
/* For WebKit-based browsers (Chrome, Safari, Edge) */
.agreeContentScrollWrap::-webkit-scrollbar {
  width: 8px;
}

.agreeContentScrollWrap::-webkit-scrollbar-track {
  background: #2b2b38;
}

.agreeContentScrollWrap::-webkit-scrollbar-thumb {
  background-color: #2b2b38 !important;
  border-radius: 10px;
  border: 2px solid #2b2b38;
}

.agreeContentScrollWrap::-webkit-scrollbar-thumb:hover {
  background-color: #2b2b38;
}

/* grand register css start */

.grandRegisterMainWrap .inputWrap {
  margin-top: 16px;
}
.grandRegisterMainWrap .divider-container {
  margin-top: 16px;
}
.grandRegisterMainWrap .divider-text::before {
  left: -62px;
}
.grandRegisterMainWrap .divider-text::after {
  right: -62px;
}
.grandRegisterMainWrap .divider-text::before,
.grandRegisterMainWrap .divider-text::after {
  width: 35%;
}
.grandRegisterMainWrap .selectGameMainWrap {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.game-item {
  height: 64px;
  position: relative;
  /* clip-path: polygon(54% -2%, 99% 24%, 99% 75%, 54% 105%, 6% 74%, 6% 24%); */
  transition: background-color 0.3s;
  padding-bottom: 5px;
  width: 62px;
}
.game-item.active::before {
  content: "";
  background-image: url(../../../images/Polygon\ 4.png) !important;
  height: 89px;
  width: 89px;
  position: absolute;
  top: -12.5px;
  left: -12.5px;
  right: 0;
  background-size: cover;
  z-index: 9;
}
.game-item img {
  position: relative;
  z-index: 9;
}
.selectGameLevelWrap {
  margin-top: 24px;
  padding: 16px;
  background: var(--Black-550, #282831);
  border: 1px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 4px 0px #ffffff05 inset;
  border-radius: 12px;
}
.selectGameLevelWrap .levelCardTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  max-height: 40px;
}
.selectGameLevelWrap .levelCardTop .cardDetails p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
.selectGameLevelWrap .levelCardTop .cardDetails .mailTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #757786;
}
.selectGameLevelWrap .levelCardTop .profileCountWrap {
  position: relative;
}
.selectGameLevelWrap .levelCardTop .profileCountWrap img {
  height: 34px;
  width: 34px;
}
.selectGameLevelWrap .levelCardTop .profileCountWrap span {
  position: absolute;
  background: var(--Blue-500, #383e4e);
  border: 1px solid var(--Blue-500, #383e4e);
  box-shadow: 0px 4px 4px 0px #ffffff0a inset;
  width: 24px;
  height: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  top: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  left: 5px;
}
.progress-bar-container {
  width: 100%;
  height: 5px;
  background: var(--Black-800, #1d1d23);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  margin-top: 16px;
}

.progress-bar {
  height: 100%;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  border-radius: 8px;
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  transition: width 0.4s ease;
}
.selectGameLevelWrap .lastLabelWrap {
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectGameLevelWrap .lastLabelWrap p {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  margin: 0px;
}
.selectGameLevelWrap .lastLabelWrap span {
  color: #757786;
  margin: 0px;
}

/* .game-item.active {
  border: 3px solid #FFFFFF1F;
    box-shadow: inset 0px 4px 80px 80px #6C5DC13D;
    height: 69px;
    width: 66px;
} */
/* .grandRegisterMainWrap .selectGameMainWrap img{
  height: 64px;
  width: 64px;
} */
.otpMainWrap {
  position: relative;
  z-index: 9999 !important;
}
.masterMainWrap .loginLogoWrap .resetPasswordBannerImg {
  margin-top: -17px;
}

@media screen and (max-width: 767px) {
  .loginMainWrap {
    height: 100vh;
    min-width: 0px;
  }
  .loginSocialBtnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    position: fixed;
    width: 90%;
    min-width: 0px;
    bottom: 16px;
  }
  .loginMainWrap::before {
    left: 50%;
    transform: translateX(-50%);
    background-position: top center;
  }
}
@media screen and (max-width: 322px) {
  .loginMainWrap::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .loginLogoWrap img {
    height: 65px;
    width: 65px;
    margin-bottom: 5px;
  }
}
